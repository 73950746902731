import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

console.log(
  `%c Environment: ${process.env.REACT_APP_ENVIRONMENT}, Firebase: ${process.env.REACT_APP_AUTH_DOMAIN}`,
  "color: pink; background-color: blue",
);

if (!app.apps.length) {
  app.initializeApp(config);
}

const firebase = app;
const fbauth = app.auth();
const fbstore = app.firestore();
const fbfunctions = app.functions();
if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
  fbfunctions.useEmulator("localhost", 5001);
}

export default firebase;
export { fbauth, fbstore, fbfunctions };
