import React from "react";
import _ from "lodash";
import { Box, Flex, Text, Spacer } from "@chakra-ui/react";
import { AudienceForm } from ".";
import { useDispatch, useSelector } from "react-redux";
import { AudienceType, GroupType, RootStateType } from "../../models";
import { initialGroup } from "../../models/group";
import { addAudience, updateGroupAudiences } from "../../store/appUser";
import ShowAudience from "./ShowAudience";
import { ReactSortable } from "react-sortablejs";

export default function ListAudiences(props: { audiences: AudienceType[] }) {
  const audiences = props.audiences.map((a) => ({ ...a, id: a.id }));
  const dispatch = useDispatch();
  const updateAudiences = (auds: AudienceType[]) => dispatch(updateGroupAudiences(auds));

  return (
    <Box maxWidth="560px" pt="16px">
      <ReactSortable
        list={audiences}
        setList={updateAudiences}
        animation={200}
        delayOnTouchOnly={true}
        delay={900}
      >
        {audiences.map((a: AudienceType) => {
          return <ShowAudience key={a.id} audience={a} />;
        })}
      </ReactSortable>
    </Box>
  );
}
