import { Box, Button, IconButton } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AudienceType, AuthStatusType, RootStateType } from "../../models";
import _ from "lodash";
import { AudienceForm, ListAudiences } from "../audience";
import { AppUserType } from "../../models/appUser";
import { addAudience } from "../../store/appUser";
import { AddIcon } from "@chakra-ui/icons";
import { initialGroup } from "../../models/group";
import { MAX_AUDIENCES_UNREGISTERED } from "../../constants";

export default function () {
  const [showAddAudienceForm, setShowAddAudienceForm] = React.useState(false);
  const appUser: AppUserType & { loading: boolean; authStatus: AuthStatusType } = useSelector(
    (s: RootStateType) => ({
      ...s.appUser,
      loading: s.auth.loading,
      authStatus: s.auth.authStatus,
    }),
  );
  const dispatch = useDispatch();

  const { groups, audiences, groupIndex, authStatus } = appUser;

  const group = groups[groupIndex || 0] || initialGroup;
  const { groupId } = group;
  const groupAudiences: AudienceType[] = audiences.filter((a) => a.groupId === groupId);

  const handleAdd = (a: AudienceType) => {
    dispatch(addAudience(a, groupId));
    toggleAddForm();
  };
  const toggleAddForm = () => setShowAddAudienceForm(!showAddAudienceForm);

  const maxAudiences = authStatus !== "signedIn" ? MAX_AUDIENCES_UNREGISTERED : 8;

  return (
    <Box>
      <Box padding={2}>
        <ListAudiences audiences={groupAudiences} />
        <Box padding={2}></Box>
        {!showAddAudienceForm && groupAudiences.length < maxAudiences && (
          <Box padding={2}>
            <Button size="xs" aria-label="Add" onClick={toggleAddForm} leftIcon={<AddIcon />}>
              Add
            </Button>
          </Box>
        )}
        {showAddAudienceForm && (
          <AudienceForm handleSubmit={handleAdd} handleCancel={toggleAddForm} />
        )}
      </Box>
    </Box>
  );
}
