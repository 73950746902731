import {
  Button,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Text,
  FormControl,
  Input,
  Box,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { emailValidation } from "../../helpers/validators";
import { fbauth } from "../../firebase";
import { setCloseAuthForms, setShowSignInForm, setShowSignUpForm } from "../../store/auth";
import { RootStateType } from "../../models";

export default function () {
  const dispatch = useDispatch();
  const close = () => dispatch(setCloseAuthForms());
  const toggleSignUpForm = () => dispatch(setShowSignUpForm(true));
  const { showSignUpForm } = useSelector((s: RootStateType) => s.auth);
  const toast = useToast();

  const { handleSubmit, errors, register, formState, setError, clearErrors } = useForm();

  async function onSubmit(values: { email: string }) {
    await fbauth
      .sendPasswordResetEmail(values.email)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
        // Not showing because we don't want user to see this
        // setError("server", { message: err.message });
      });

    toast({
      title: "Email Sent",
      description: "Follow the email link to reset your password",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    close();
    dispatch(setShowSignInForm(true));
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PopoverHeader fontWeight="semibold">Reset Password</PopoverHeader>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>
        <Box align="left">
          <FormControl isInvalid={errors.email}>
            <Input
              variant="flushed"
              name="email"
              placeholder="Email"
              onChange={() => clearErrors()}
              ref={register(emailValidation)}
            />
            <Text fontSize="sm" color="red">
              {errors.email && errors.email.message}
            </Text>
          </FormControl>
        </Box>
      </PopoverBody>
      <PopoverFooter>
        <HStack>
          <Box flex={1} d="flex" justifyContent="flex-start">
            <Text style={{ cursor: "pointer" }} onClick={toggleSignUpForm}>
              Sign Up
            </Text>
          </Box>
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={close}>
              Cancel
            </Button>
            <Button colorScheme="teal" isLoading={formState.isSubmitting} type="submit">
              Request
            </Button>
          </ButtonGroup>
        </HStack>
      </PopoverFooter>
    </form>
  );
}
