import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Text,
  FormControl,
  Input,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { emailValidation, passwordValidation } from "../../helpers/validators";
import { fbauth } from "../../firebase";
import { setCloseAuthForms, setShowSignInForm } from "../../store/auth";
import { RootStateType } from "../../models";

export default function () {
  const dispatch = useDispatch();
  const open = () => dispatch(setShowSignInForm(true));
  const close = () => dispatch(setCloseAuthForms());
  const toggle = () => (showSignInForm ? close() : open());

  const showSignInForm = useSelector((state: RootStateType) => state.auth.showSignInForm);

  const { handleSubmit, errors, register, formState, setError, clearErrors } = useForm();

  async function onSubmit(values: { email: string; password: string }) {
    clearErrors();
    return await fbauth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => close()) // useFB hook will detect change and take action
      .catch((err) => {
        console.error(err);
        setError("server", { message: "Please check your credentials" });
      });
  }

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={showSignInForm}
        placement="bottom-start"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Text style={{ cursor: "pointer" }} onClick={toggle} fontSize={18} fontWeight={500}>
            Sign In
          </Text>
        </PopoverTrigger>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PopoverContent color="black">
            <PopoverHeader fontWeight="semibold">Sign In</PopoverHeader>
            <PopoverArrow />
            <PopoverBody>
              <Box align="left">
                <FormControl isInvalid={errors.email}>
                  <Input
                    variant="flushed"
                    name="email"
                    placeholder="Email"
                    onChange={() => clearErrors()}
                    ref={register(emailValidation)}
                  />
                  <Text fontSize="sm" color="red">
                    {errors.email && errors.email.message}
                  </Text>
                </FormControl>
                <FormControl isInvalid={errors.password}>
                  <Input
                    variant="flushed"
                    name="password"
                    placeholder="Password"
                    type="password"
                    onChange={() => clearErrors()}
                    ref={register(passwordValidation)}
                  />
                  <Text fontSize="sm" color="red">
                    {errors.password && errors.password.message}
                    {errors.server && errors.server.message}
                  </Text>
                </FormControl>
              </Box>
            </PopoverBody>
            <PopoverFooter d="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button variant="outline" onClick={close}>
                  Cancel
                </Button>
                <Button colorScheme="teal" isLoading={formState.isSubmitting} type="submit">
                  Sign In
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </form>
      </Popover>
    </>
  );
}
