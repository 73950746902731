import { IconButton } from "@chakra-ui/button";
import { CalendarIcon } from "@chakra-ui/icons";
import React from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";

interface Props {
  isClearable?: boolean;
  onChange?: (date: Date) => void;
  selected?: Date;
  showPopperArrow?: boolean;
  value?: any;
  iconOnly?: boolean;
}

const ButtonInput = React.forwardRef((props: { onClick: () => void }, ref) => {
  const { onClick } = props;
  return (
    <IconButton
      colorScheme="whiteAlpha"
      size="xs"
      aria-label="Set Date"
      icon={<CalendarIcon />}
      onClick={onClick}
    />
  );
});

const DatePicker = (props: Props) => {
  return (
    <ReactDatePicker
      selected={props.selected || new Date()}
      onChange={props.onChange}
      dateFormat="MMMM d, yyyy"
      customInput={props.iconOnly ? <ButtonInput onClick={() => {}} /> : null}
    />
  );
};

export default DatePicker;
