import { EditIcon, EmailIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Icon, IconButton, Select, Text } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { AudienceType } from "../../models";
import { updateAudience, deleteAudience } from "../../store/appUser";
import { ItemBox } from "../common";
import AudienceForm from "./AudienceForm";

export default function (props: { audience: AudienceType }) {
  const { title, location, selected, email } = props.audience;
  const [editMode, setEditMode] = React.useState(false);
  const toggleEdit = () => setEditMode(!editMode);
  const dispatch = useDispatch();

  const handleEdit = (a: AudienceType) => {
    dispatch(updateAudience(a));
    toggleEdit();
  };

  const handleDelete = () => dispatch(deleteAudience(props.audience));

  if (editMode)
    return (
      <AudienceForm
        audience={props.audience}
        handleSubmit={handleEdit}
        handleCancel={toggleEdit}
        handleDelete={handleDelete}
      />
    );

  return (
    <ItemBox>
      <Checkbox
        isChecked={selected}
        colorScheme="facebook"
        size="sm"
        onChange={(e) => dispatch(updateAudience({ ...props.audience, selected: !selected }))}
        mt="2px"
        ml="8px"
      />
      <Box flex={8} lineHeight={email ? 1.1 : 1.5}>
        <Text textAlign="left" fontSize="md" fontWeight="semibold" pl={2} isTruncated>
          {title}
        </Text>
        {email && (
          <Text flex={8} textAlign="left" fontSize="xs" pl={2} isTruncated>
            {email}
          </Text>
        )}
      </Box>
      <Box flex={14} textAlign="left" fontSize="md" pl={2} isTruncated>
        {location.value.replace("_", " ")}
      </Box>
      <IconButton icon={<EditIcon />} size="xs" mb="2px" aria-label="Edit" onClick={toggleEdit} />
    </ItemBox>
  );
}
