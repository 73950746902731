import React from "react";
import { fbauth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuthStatus } from "../../helpers/auth";
import { useDispatch, useSelector } from "react-redux";
import { setAuthStatus, setAuth } from "../../store/auth";
import { RootStateType, initialAuthUser } from "../../models";
import { initializeAppSignedOut } from "../../store/appUser";

export default function () {
  const auth = useAuthState(fbauth); // [user, loading, error]
  const authStatus = getAuthStatus(auth);
  const dispatch = useDispatch();
  const uid = useSelector((state: RootStateType) => state.auth.uid);

  React.useEffect(() => {
    dispatch(setAuthStatus(authStatus));
    if (authStatus === "signedIn") {
      dispatch(setAuth(auth[0] || initialAuthUser));
      // } else if (uid && !authStatus) {
      // This user should be signed out
      // dispatch(initializeAuth());
    } else if (!uid && !authStatus) {
      dispatch(initializeAppSignedOut());
    }
  }, [authStatus]);
}
