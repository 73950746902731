import React from "react";
import { Box } from "@chakra-ui/react";
import { TimeDisplayOptions, TimeForm } from "./";
import { AudienceType, initialTimeSetting, RootStateType } from "../../models";
import { ItemBox } from "../common";
import { DateTime, Settings as DateTimeSettings } from "luxon";
import ListTimes from "./ListTimes";
import { useSelector } from "react-redux";
import ShowTime from "./ShowTime";

export default function () {
  const [timeSetting, setTimeSetting] = React.useState(initialTimeSetting);

  const state = useSelector((s: RootStateType) => {
    const { appUser, auth } = s;
    if (auth.loading || appUser.groups.length < 1) return {};
    const { groups, groupIndex, audiences } = appUser;
    const group = groups[groupIndex || 0];
    const { groupId } = group;
    const groupAudiences = audiences.filter((a: AudienceType) => {
      if (a.groupId === groupId && a.selected) return true;
    });
    return { group, groupAudiences };
  });

  const { group, groupAudiences } = state;
  React.useEffect(() => {
    if (group?.timeSetting) setTimeSetting({ ...initialTimeSetting, ...group.timeSetting });
  }, [group]);

  if (!group || groupAudiences.length < 1) return <></>;

  const handleTimeSettingChange = (setting: object) =>
    setTimeSetting({ ...timeSetting, ...setting });

  const { hour, minute, lengthInMinutes, location, twelveHour, includeLength, date } = timeSetting;
  DateTimeSettings.defaultZoneName = groupAudiences[0].location.value;
  const dt = DateTime.fromMillis(date).set({ hour, minute });
  const endDt = dt.plus({ minutes: lengthInMinutes });

  return (
    <Box
      display="flex"
      flexDir="column"
      border="1px solid #c1c1c1"
      borderRadius={6}
      width="560px"
      spacing="0px"
    >
      <Box
        display="flex"
        flexDirection="row"
        flex={1}
        color="white"
        bg="blue.800"
        // borderRadius={4}
        width="100%"
        px="5%"
        py="3%"
        align="flex-start"
      >
        <ShowTime timeSetting={timeSetting} location={groupAudiences[0]} />
        <TimeForm
          handleChange={handleTimeSettingChange}
          timeSetting={timeSetting}
          location={groupAudiences[0]}
        />
      </Box>
      <Box pl="24px" display="flex" flexDirection="column" alignItems="flex-end" width="100%">
        <ListTimes
          dt={dt}
          endDt={endDt}
          timeSetting={timeSetting}
          audiences={groupAudiences}
          group={group}
        />
      </Box>
      <Box pt="20px" pb="4px" borderTop="1px solid #e6e6e6" px="0px" m="0px">
        <TimeDisplayOptions handleChange={handleTimeSettingChange} timeSetting={timeSetting} />
      </Box>
      <ItemBox column></ItemBox>
    </Box>
  );
}
