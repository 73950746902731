export interface TimeFormatCheckboxType {
  title: string;
  using: boolean;
  key: string;
  label: string;
}

export default [
  { title: "", using: true, key: "twelveHour", label: "12-Hour" },
  { title: "", using: true, key: "includeTimeZone", label: "Location" },
  { title: "", using: true, key: "includeTimeOffset", label: "Offset" },
  { title: "", using: true, key: "splitLines", label: "Split Lines" },
  { title: "", using: true, key: "includeDate", label: "Date" },
  { title: "", using: true, key: "includeDayOfWeek", label: "Day of Week" },
  { title: "", using: true, key: "includeYear", label: "Year" },
  { title: "", using: true, key: "includeEndTime", label: "End Time" },
  { title: "", using: false, key: "includeLength", label: "Length" },
  { title: "", using: true, key: "dateType", label: "Type" },
  { title: "", using: false, key: "includeEmail", label: "Email" },
];
