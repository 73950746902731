import React from "react";
import {
  Box,
  Text,
  FormControl,
  Input,
  ButtonGroup,
  Button,
  Spacer,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { AudienceType, initialAudience } from "../../models";
import { emailValidation, titleValidation } from "../../helpers/validators";
import { ItemBox, LocationSelector } from "../common";
import { CheckIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { connect } from "react-redux";
import { random } from "../../helpers";

type Props = {
  audience?: AudienceType;
  handleSubmit: (arg0: AudienceType) => void;
  handleCancel: Function;
  handleDelete?: Function;
};

export default function AudienceForm(props: Props) {
  const initValues = props.audience ? props.audience : initialAudience;
  const { handleSubmit, errors, register, formState, control, getValues } = useForm({
    defaultValues: initValues,
  });

  async function onSubmit(values: AudienceType) {
    values = { ...initValues, ...values };
    if (!props.audience) values.id = random();
    props.handleSubmit(values);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ItemBox>
        <Box flex={8} minWidth={160}>
          <FormControl isInvalid={!!errors.title}>
            <Input
              variant="flushed"
              name="title"
              size="xs"
              placeholder="Title"
              ref={register(titleValidation)}
              maxLength={24}
            />
            <Text fontSize="sm" color="red">
              {errors.title && errors.title.message}
            </Text>
          </FormControl>
          <FormControl isInvalid={!!errors.title}>
            <Input
              variant="flushed"
              name="email"
              size="xs"
              placeholder="Email"
              ref={register({ ...emailValidation, required: "" })}
              maxLength={24}
            />
            <Text fontSize="sm" color="red">
              {errors.email && errors.email.message}
            </Text>
          </FormControl>
        </Box>
        <Box flex={12} minWidth={180} alignSelf="flex-start">
          {/* Not using a register because we don't this to become null */}
          <LocationSelector control={control} location={props.audience?.location} />
        </Box>
        <ButtonGroup size="sm" pt="2px">
          {props.audience && (
            <IconButton
              aria-label="Cancel"
              size="xs"
              onClick={() => props.handleDelete()}
              icon={<DeleteIcon />}
            />
          )}
          {!props.audience && (
            <IconButton
              aria-label="Cancel"
              size="xs"
              onClick={() => props.handleCancel()}
              icon={<CloseIcon />}
            />
          )}
          <IconButton
            aria-label="Done"
            colorScheme="teal"
            size="xs"
            icon={<CheckIcon />}
            isLoading={formState.isSubmitting}
            type="submit"
          />
        </ButtonGroup>
      </ItemBox>
    </form>
  );
}
