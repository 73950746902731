import {
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Text,
} from "@chakra-ui/react";
import { DatePicker } from "../common";
import { LocationType, TimeSettingType } from "../../models";

type Props = { handleChange: Function; timeSetting: TimeSettingType; location: LocationType };

export default function (props: Props) {
  const { handleChange, timeSetting, location } = props;
  const { hour, minute, lengthInMinutes, date } = timeSetting;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDir="row">
        <Box flex={1} display="flex" alignItems="flex-start">
          <DatePicker
            selected={date ? new Date(date) : new Date()}
            onChange={(e) => handleChange({ date: e.getTime() })}
            iconOnly
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Text fontSize="md" px="6px">
            Mins:
          </Text>
          <NumberInput
            size="xs"
            width="60px"
            defaultValue={60}
            value={lengthInMinutes}
            onChange={(e) => {
              console.log(e, typeof e);
              let lengthInMinutes: number | string = +e;
              if (lengthInMinutes > 180) return;
              handleChange({ ...timeSetting, lengthInMinutes });
            }}
            min={0}
            max={180}
            step={15}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
      </Box>
      <Box pt="8px">
        <HStack flex={36} direction="row">
          <Text fontSize="md">At</Text>
          <Box>
            <NumberInput
              size="xs"
              defaultValue={10}
              width="60px"
              value={hour}
              onChange={(e) => {
                let hour: number | string = +e.substr(e.length - 2);
                if (!hour && hour !== 0) return;
                if (hour > 23) return;
                if (hour < 10) hour = "0" + hour;
                handleChange({ ...timeSetting, hour });
              }}
              min={0}
              max={23}
              step={1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box>
            <NumberInput
              size="xs"
              defaultValue={15}
              width="60px"
              value={minute}
              onChange={(e) => {
                let minute: number | string = +e.substr(e.length - 2);
                if (!minute && minute !== 0) return;
                if (minute > 59) return;
                if (minute < 10) minute = "0" + minute;
                handleChange({ ...timeSetting, minute });
              }}
              min={0}
              max={59}
              step={5}
              keepWithinRange
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}
