import { Box, Button } from "@chakra-ui/react";
import "./App.css";

import { Header } from "./components/header";
import { useApp, useAuth } from "./components/hooks";
import { Group, GroupHeader } from "./components/group";
import { TimeHolder } from "./components/time";
import { fbfunctions } from "./firebase";
import { useSelector } from "react-redux";
import { RootStateType } from "./models";

const isLocalHost = process.env.REACT_APP_ENVIRONMENT === "LOCAL";

// const loadLinks = async () => {
//   console.log("Trying to use functions");

//   try {
//     const res = await fetch("/.netlify/lambda/helloWorld");
//     const links = await res.json();
//     console.log(links);
//   } catch (err) {
//     console.error(err);
//   }
// };

const functionTest = async () => {
  const log = fbfunctions.httpsCallable("helloWorld");
  log()
    .then((res: any) => console.log(res))
    .catch((err) => console.error(err));
};

function App() {
  useAuth();
  useApp();
  // React.useEffect(() =>  ,[])
  const { authStatus } = useSelector((s: RootStateType) => s.auth);

  return (
    <Box className="App" minH="850px">
      <Header />

      {/* {isLocalHost && <Button onClick={() => loadLinks()}>Test Netlify</Button>} */}
      {isLocalHost && <Button onClick={() => functionTest()}>Test FB Functions</Button>}
      <Box padding={8}></Box>
      <Box align="center">
        {authStatus === "signedIn" && (
          <Box width={560}>
            <GroupHeader />
          </Box>
        )}
        <Box width={560}>
          <TimeHolder />
          <Group />
        </Box>
      </Box>
    </Box>
  );
}

export default App;
