import { Box, Heading, Text } from "@chakra-ui/react";
import { AudienceType, TimePreviewType, TimeSettingType } from "../../models";
import { DateTime } from "luxon";
import { outputParser } from "../../helpers/parsing";
import React from "react";

interface Props {
  location: AudienceType;
  timeSetting: TimeSettingType;
}

export default function (props: Props) {
  const { timeSetting, location } = props;
  const { dateLong, startTime, endTime, lengthInMinutes } = React.useMemo(
    () => outputParser(timeSetting, location),
    [timeSetting, location],
  );
  return (
    <Box display="flex" flexDirection="column" flex={1} justifyContent="center">
      <Box textAlign="left">
        <Heading fontSize="xl" fontWeight={700} lineHeight="28px">
          On {dateLong}
        </Heading>
      </Box>
      <Box display="flex" textAlign="left" justifyContent="flex-start" pt="4px">
        <Text fontSize="md" fontWeight={500} lineHeight="16px">
          {lengthInMinutes ? "From" : "At"} {startTime} {lengthInMinutes > 0 && `to ${endTime}`}
        </Text>
      </Box>
    </Box>
  );
}
