import { AudienceType } from "./audience";
import { appVersion } from "../helpers";
import { GroupType } from "./group";

export interface AppUserType {
  appVersion: number;
  uid: string;
  groups: GroupType[];
  audiences: AudienceType[];
  dateCreated: string;
  groupIndex?: number;
}

export let initialAppUser: AppUserType = {
  appVersion,
  uid: "",
  groups: [],
  audiences: [],
  dateCreated: Date.now().toString(),
};
