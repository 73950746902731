import { AudienceType, TimeSettingType } from "../models";
import { outputParser } from "./parsing";

const calendarLink = "https://calendar.google.com/calendar/r/eventedit?";

const formatLink = (timeSetting: TimeSettingType, audiences: AudienceType[]): string => {
  let options: string[] = [];
  const format = outputParser(timeSetting, audiences[0]);

  console.log("format", format);

  options.push("text=New Event");
  options.push("details=Made with Huddlein.co");
  options.push("dates=" + format.googleCalDt);
  options.push("ctz=" + format.timeZone);
  const emails = audiences.filter((a) => a.email).map((a: AudienceType) => a.email);
  options.push("add=" + emails.join("&add="));

  return calendarLink + options.join("&");
};

export { calendarLink, formatLink };
