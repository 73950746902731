import { DateTime } from "luxon";
import { AudienceType, TimeSettingType } from "../../models";

interface OutputType {
  string: string;
  startTime: string;
  length: string;
  endTime: string;
  date: string;
  dateLong: string;
  holder: string[];
  dt?: DateTime;
  endDt?: DateTime;
  lengthInMinutes: number;
  googleCalDt: string;
  timeZone: string;
}

export default (timeSetting: TimeSettingType, audience?: AudienceType) => {
  let output: OutputType = {
    string: "",
    startTime: "",
    length: "",
    endTime: "",
    date: "",
    dateLong: "",
    holder: [],
    lengthInMinutes: 0,
    googleCalDt: "",
    timeZone: "",
  };

  const {
    hour,
    minute,
    lengthInMinutes,
    twelveHour,
    includeTimeZone,
    date,
    dateType,
    includeEndTime,
    includeDate,
    includeYear,
    includeDayOfWeek,
    splitLines,
    includeTimeOffset,
  } = timeSetting;
  const timeZone = audience?.location?.value;
  let dt = DateTime.fromMillis(date).set({ hour, minute });
  if (audience) dt = dt.setZone(audience.location.value);
  const endDt = dt.plus({ minutes: lengthInMinutes });
  const timeTextFormatting = twelveHour ? DateTime.TIME_SIMPLE : DateTime.TIME_24_SIMPLE;
  output.dt = dt;
  output.endDt = endDt;
  output.lengthInMinutes = lengthInMinutes;
  output.googleCalDt =
    `${dt.toFormat("yMMdd")}T${dt.toFormat("hhmm")}00` +
    `/${endDt.toFormat("yMMdd")}T${endDt.toFormat("hhmm")}00`;

  output.timeZone = timeZone;

  if (twelveHour) {
    output.startTime = dt.toFormat("h:mma").toLowerCase();
    output.endTime = endDt.toFormat("h:mma").toLowerCase();
  } else {
    output.startTime = dt.toLocaleString(timeTextFormatting);
    output.endTime = endDt.toLocaleString(timeTextFormatting);
  }
  output.length = "" + lengthInMinutes + " minutes";
  // Date Section
  if (includeDate) {
    if (dateType === "long") {
      output.date = dt.toFormat("MMMM d");
      output.date += includeYear ? dt.toFormat(", yy") : "";
    }
    if (dateType === "short") {
      output.date = dt.toFormat("MMM d");
      output.date += includeYear ? dt.toFormat(", yy") : "";
    }
    if (dateType === "condensed") {
      output.date = dt.toFormat("m/d");
      output.date += includeYear ? dt.toFormat(", yy") : "";
    }
  }

  output.dateLong = dt.toFormat("MMMM d, yyyy");

  let holder = [];
  audience && holder.push(audience.title);
  if (includeTimeOffset || includeTimeZone) holder.push("|");
  if (includeTimeZone) holder.push(`${timeZone.replace("_", " ")}`);
  if (includeTimeOffset) holder.push(`${dt.toFormat("ZZZZ")}`);

  splitLines ? holder.push("\n\u00a0\u00a0") : holder.push("\u00a0⇒\u00a0");
  if (includeDayOfWeek) holder.push(dt.toFormat("EEE,"));
  if (includeDate) holder.push(output.date);
  let time = [];
  time.push(output.startTime);
  if (includeEndTime) time.push("-" + output.endTime);
  holder.push(time.join(""));
  output.string = holder.join(" ");

  output.holder = holder.map((h) => h.replace(/ /g, "\u00a0"));
  return output;
};
