import { LocationType } from "./";
import { appVersion } from "../helpers";
import { initialLocation } from "./";

export interface AudienceType {
  appVersion: number;
  groupId: string;
  title: string;
  location: LocationType;
  dateCreated: string;
  selected: boolean;
  email: string;
  id: string;
}

export let initialAudience: AudienceType = {
  id: "",
  appVersion,
  groupId: "",
  title: "",
  location: initialLocation,
  dateCreated: Date.now().toString(),
  selected: true,
  email: "",
};
