import { Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { signout } from "../../store/auth";

export default function SignOut() {
  const dispatch = useDispatch();
  return (
    <Text
      style={{ cursor: "pointer" }}
      fontSize={16}
      fontWeight={500}
      onClick={() => dispatch(signout())}
    >
      Sign Out
    </Text>
  );
}
