import { Box } from "@chakra-ui/react";

interface Props {
  width?: number;
}

export default function (props: Props) {
  const size = props.width ? `${props.width.toString()}px` : "30px";
  return (
    <Box width={size} height={size}>
      <svg viewBox="0 0 100 100">
        <path
          d="M50,100A50,50,0,1,0,0,50,50,50,0,0,0,50,100ZM46.26,20.07h6.25V47.84H80.29V54.1h-34ZM27.63,21.43h6.26V66.07H78.52v6.24H27.63Z"
          style={{ fill: "#fff" }}
        />
      </svg>
    </Box>
  );
}
