import produce from "immer";
import { initialTimeSetting, TimeSettingType } from "../../models";
import { SET_TIME_SETTING, SET_TIME_SETTING_HOUR } from "./actions";

export default produce((draft: TimeSettingType, action: any) => {
  switch (action.type) {
    case SET_TIME_SETTING:
      return { ...draft, ...action.payload };
    case SET_TIME_SETTING_HOUR:
      console.log("action", action);

      draft.hour = action.payload;
      return;

    default:
      return;
  }
}, initialTimeSetting);
