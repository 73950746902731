import React, { CSSProperties } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { timezones } from "../../helpers";
import { LocationType } from "../../models";

const customStyles = {
  option: (provided: CSSProperties, state: any) => ({
    ...provided,
  }),
  menu: (provided: CSSProperties, state: any) => {
    return {
      ...provided,
      alignItems: "left",
    };
  },
  input: (provided: CSSProperties) => {
    return {
      ...provided,
      height: "30px",
      borderColor: "#E2E8F0",
    };
  },
  control: (provided: CSSProperties) => ({
    ...provided,
    height: "24px",
    minHeight: "20px",
    alignItems: "left",
    padding: 0,
    border: "0",
    borderRadius: "0",
    borderBottom: "1px solid #E2E8F0",
  }),
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
    position: "initial",
    height: 24,
    lineHeight: 1.2,
  }),
  indicatorsContainer: (provided: CSSProperties, state: any) => {
    return { ...provided, height: 28, padding: 0, paddingBottom: 6 };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
};

function LocationSelector(props: { control: any; location?: LocationType }) {
  const initLocation = props.location || {
    value: "America/New_York",
    label: "New York",
  };

  const zones = React.useMemo(
    () => timezones.map((t) => ({ value: t.value, label: t.value.replace("_", " ") })),
    [timezones],
  );

  return (
    <Controller
      as={<Select />}
      name="location"
      control={props.control}
      options={zones}
      styles={customStyles}
      // isClearable={false}
      defaultInputValue={initLocation.value.replace("_", " ")}
      defaultValue={initLocation.value.replace("_", " ")}
    />
  );
}

export default LocationSelector;
