import { IconButton } from "@chakra-ui/button";
import { Editable, EditableInput, EditablePreview } from "@chakra-ui/editable";
import {
  AddIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { Box, Center, Heading } from "@chakra-ui/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupTitle } from ".";
import { GroupType, RootStateType } from "../../models";
import { addGroup, deleteGroup, setGroupIndex, updateGroup } from "../../store/appUser";
import { EditableHeading } from "../common";

export default function () {
  const s = useSelector((s: RootStateType) => {
    const { groupIndex, groups }: { groupIndex: number; groups: GroupType[] } = s.appUser;
    const authStatus = s.auth.authStatus;
    const group = groups[groupIndex];
    return { groupIndex, group, groups, authStatus };
  });
  const dispatch = useDispatch();
  const { groupIndex, group, groups, authStatus } = s;

  const groupLength: number = groups?.length || 0;
  const [editTitleMode, setEditTitleMode] = React.useState(false);
  const [groupTitle, setGroupTitle] = React.useState(group?.groupTitle || "Group");
  React.useEffect(() => {
    if (group) {
      setGroupTitle(group?.groupTitle);
      setEditTitleMode(false);
    }
  }, [group]);

  function handleTitleSubmit(): void {
    if (groupTitle.length > 0) dispatch(updateGroup({ ...group, groupTitle }));
    if (groupTitle === group.groupTitle) setEditTitleMode(false);
  }

  const showAdd = !editTitleMode && groupIndex + 1 >= groupLength && groupLength <= 6;
  if (!group) return null;

  return (
    <Box pb="12px" display="flex">
      <Box flex={1} display="flex">
        <Box mr="8px" width="40px">
          {groupIndex !== 0 && (
            <IconButton
              aria-label="Previous"
              icon={<ChevronLeftIcon />}
              onClick={() => dispatch(setGroupIndex(groupIndex - 1))}
              variant="ghost"
              size="sm"
            />
          )}
        </Box>
        <Box align="center" maxWidth="240px">
          {!editTitleMode && (
            <Heading fontSize="2xl" fontWeight="bold" onClick={() => setEditTitleMode(true)}>
              {groupTitle}
            </Heading>
          )}
          {editTitleMode && (
            <Center>
              <Input
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
                size="xs"
                maxLength={16}
              />
              <IconButton
                ml="8px"
                size="xs"
                onClick={handleTitleSubmit}
                aria-label="Done"
                icon={<CheckIcon />}
              />
            </Center>
          )}
        </Box>
        <Box pl="8px">
          {groupIndex !== groupLength - 1 && (
            <IconButton
              aria-label="Next"
              icon={<ChevronRightIcon />}
              onClick={() => {
                console.log(groupIndex);
                dispatch(setGroupIndex(groupIndex + 1));
              }}
              variant="ghost"
              size="sm"
            />
          )}
        </Box>
      </Box>
      <Center>
        <IconButton
          aria-label="Delete Group"
          icon={<DeleteIcon />}
          onClick={() => dispatch(deleteGroup(group))}
          size="xs"
        />
        {showAdd && (
          <IconButton
            aria-label="Add Group"
            icon={<AddIcon />}
            onClick={() => dispatch(addGroup())}
            size="xs"
            mx="8px"
          />
        )}
      </Center>
    </Box>
  );
}
