import {
  Button,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Text,
  FormControl,
  Input,
  Box,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { emailValidation, passwordValidation } from "../../helpers/validators";
import { fbauth } from "../../firebase";
import { RootStateType } from "../../models";
import { setCloseAuthForms, setShowResetPasswordForm, testOf } from "../../store/auth";

export default function () {
  const dispatch = useDispatch();
  const close = () => dispatch(setCloseAuthForms());
  const { showResetPasswordForm } = useSelector((s: RootStateType) => s.auth);
  const toggleResetForm = () => dispatch(setShowResetPasswordForm(!showResetPasswordForm));

  const { handleSubmit, errors, register, formState, setError, clearErrors } = useForm();

  React.useEffect(() => {
    dispatch(testOf("string"));
  }, []);

  async function onSubmit(values: { email: string; password: string }) {
    const { email, password } = values;
    await fbauth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => res.user?.sendEmailVerification())
      .catch((err) => setError("server", { message: err.message }));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PopoverHeader fontWeight="semibold">Sign Up</PopoverHeader>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>
        <Box align="left">
          <FormControl isInvalid={errors.email}>
            <Input
              variant="flushed"
              name="email"
              placeholder="Email"
              onChange={() => clearErrors()}
              ref={register(emailValidation)}
            />
            <Text fontSize="sm" color="red">
              {errors.email && errors.email.message}
            </Text>
          </FormControl>
          <FormControl isInvalid={errors.password}>
            <Input
              variant="flushed"
              name="password"
              placeholder="Password"
              type="password"
              ref={register(passwordValidation)}
            />
            <Text fontSize="sm" color="red">
              {errors.password && errors.password.message}
              {errors.server && errors.server.message}
            </Text>
          </FormControl>
        </Box>
        <Text pt="6px" fontSize="xs" color="gray.500">
          Signing up gets you syncing and 2 groups of 8 people
        </Text>
      </PopoverBody>
      <PopoverFooter>
        <HStack>
          <Box flex={1} d="flex" justifyContent="flex-start">
            <Text style={{ cursor: "pointer" }} onClick={toggleResetForm}>
              Reset
            </Text>
          </Box>
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={close}>
              Cancel
            </Button>
            <Button colorScheme="teal" isLoading={formState.isSubmitting} type="submit">
              Sign Up
            </Button>
          </ButtonGroup>
        </HStack>
      </PopoverFooter>
    </form>
  );
}
