import { fbauth } from "../firebase";
import { AuthStatusType } from "../models";

export async function emailExists(email: string) {
  const registrationStatus = await fbauth.fetchSignInMethodsForEmail(email);
  console.log(registrationStatus);

  return registrationStatus.length > 0;
}

export async function requestPasswordReset(email: string) {
  const request = await fbauth.sendPasswordResetEmail(email);
  console.log("request", request);
}

export function getAuthStatus(auth: [any, boolean, any]): AuthStatusType {
  if (auth[1]) {
    return "loading";
  } else if (!auth[0]) {
    return "";
  } else if (auth[0] && auth[0].uid && !auth[0].emailVerified) {
    return "unverified";
  } else if (auth[0].uid) {
    return "signedIn";
  }
  return "";
}
