import { initialLocation, LocationType } from "./";
import { DateTime } from "luxon";
import { appVersion } from "../helpers";

export let initialTimeSetting = {
  appVersion: appVersion,
  hour: 10,
  minute: 15,
  date: DateTime.now().toMillis(),
  lengthInMinutes: 75,
  location: initialLocation,
  twelveHour: true,
  includeLength: true,
  includeTimeZone: false,
  includeDate: true,
  includeYear: true,
  dateType: <const>"short",
  includeEndTime: true,
  includeEmail: true,
  includeDayOfWeek: false,
  splitLines: false,
  includeTimeOffset: true,
};

export interface TimeSettingType {
  appVersion: number;
  hour: number;
  minute: number;
  date: number;
  lengthInMinutes: number;
  location: LocationType;
  twelveHour: boolean;
  includeLength: boolean;
  includeTimeZone: boolean;
  includeYear: boolean;
  dateType: "long" | "short" | "condensed";
  includeDate: boolean;
  includeEndTime: boolean;
  includeEmail: boolean;
  includeDayOfWeek: boolean;
  splitLines: boolean;
  includeTimeOffset: boolean;
}

export interface TimePreviewType {
  dt: DateTime;
  endDt: DateTime;
  timeSetting: TimeSettingType;
}

export let intitialTimePreview = {
  timeStartText1: "",
  timeEndText: "",
  timeTextFull: "",
  dateText: "",
};

export interface GoogleCalendarLinkType {
  name: string;
  dates: string;
  ctz: string;
  details?: string;
  location?: string;
  add?: string;
}
