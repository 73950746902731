import produce from "immer";
import { AppUserType, initialAppUser } from "../../models/appUser";
import {
  SET_AUDIENCES,
  INITIALIZE_APP_USER,
  SET_GROUPS,
  CLEAR_APP_USER,
  SET_GROUP_INDEX,
} from "./actions";

export default produce((draft: AppUserType, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case INITIALIZE_APP_USER: {
      return { ...draft, ...payload };
    }
    case SET_AUDIENCES:
      draft.audiences = [...payload.audiences];
      return;
    case SET_GROUPS:
      draft.groups = [...payload.groups];
      return;
    case CLEAR_APP_USER:
      draft = initialAppUser;
      return;
    case SET_GROUP_INDEX:
      draft.groupIndex = payload.groupIndex;
      return;
    default:
      return;
  }
}, initialAppUser);
