import { group } from "node:console";
import { Dispatch } from "react";
import { fbstore } from "../../firebase";
import { random } from "../../helpers";
import { getAppUser, setAppUser, setGroupId } from "../../helpers/localStorage";
import normalizeAppUser from "../../helpers/normalizeAppUser";
import { AudienceType, RootStateType } from "../../models";
import { AppUserType } from "../../models/appUser";
import { GroupType, initialGroup } from "../../models/group";

export const SET_AUDIENCES = "SET_AUDIENCES";
export const INITIALIZE_APP_USER = "INITIALIZE_APP_USER";
export const SET_GROUPS = "SET_GROUPS";
export const CLEAR_APP_USER = "CLEAR_APP_USER";
export const SET_GROUP_INDEX = "SET_GROUP_INDEX";

export function addAudience(audience: AudienceType, groupId: string) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    audience.groupId = groupId;
    const { auth, appUser } = getState();
    const audiences = [...appUser.audiences, audience];

    if (auth.authStatus !== "signedIn") {
      console.log("audiences", audiences);
      setAppUser({ ...appUser, audiences });
    } else {
      fbstore.collection("users").doc(auth.uid).update({ audiences: audiences });
    }

    dispatch({ type: SET_AUDIENCES, payload: { audiences } });
  };
}

export function deleteAudience(audience: AudienceType) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const { appUser, auth } = getState();
    const audiences = appUser.audiences.filter((aud: AudienceType) => {
      if (aud.id !== audience.id) return audience;
    });
    if (!auth.authStatus) {
      console.log("audiences", audiences);
      setAppUser({ ...appUser, audiences });
    }

    dispatch({ type: SET_AUDIENCES, payload: { audiences } });
  };
}

export function updateAudience(audience: AudienceType) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const { auth, appUser } = getState();
    const audiences = appUser.audiences.map((a: AudienceType) => {
      if (a.id === audience.id) return audience;
      return a;
    });
    if (auth.authStatus !== "signedIn") {
      console.log("audiences", audiences);
      setAppUser({ ...appUser, audiences });
    } else {
      fbstore.collection("users").doc(auth.uid).update({ audiences: audiences });
    }

    dispatch({ type: SET_AUDIENCES, payload: { audiences } });
  };
}

export function editAudience(audience: AudienceType) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const { appUser } = getState();
    const audiences = appUser.audiences.map((a: AudienceType) => {
      if (a.id === audience.id) return audience;
      return a;
    });

    dispatch({ type: SET_AUDIENCES, payload: { audiences } });
  };
}

export function updateGroupAudiences(groupAudiences: AudienceType[]) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    if (groupAudiences.length < 1) return;
    const { auth, appUser } = getState();
    const { groupId } = groupAudiences[0];
    const audiences = [
      ...appUser.audiences.filter((a: AudienceType) => a.groupId !== groupId),
      ...groupAudiences,
    ];

    if (auth.authStatus !== "signedIn") {
      console.log("audiences", audiences);
      setAppUser({ ...appUser, audiences });
    } else {
      fbstore.collection("users").doc(auth.uid).update({ audiences: audiences });
    }

    dispatch({ type: SET_AUDIENCES, payload: { audiences } });
  };
}

export function addGroup() {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const { appUser, auth } = getState();
    const groups = [...appUser.groups, { ...initialGroup, groupId: random() }];
    fbstore.collection("users").doc(auth.uid).update({ groups });
    dispatch({ type: SET_GROUPS, payload: { groups } });
    dispatch(setGroupIndex(groups.length - 1));
  };
}

export function deleteGroup(group: GroupType) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const { appUser, auth } = getState();
    const groups = appUser.groups.filter((g: GroupType) => g.groupId !== group.groupId);
    fbstore.collection("users").doc(auth.uid).update({ groups });

    dispatch({ type: SET_GROUPS, payload: { groups } });
    if (appUser.groupIndex !== 0) dispatch(setGroupIndex(groups.length - 1));
  };
}

export function setGroupIndex(groupIndex: number) {
  return (dispatch: Dispatch<{}>) => {
    // const { appUser } = getState();
    setGroupId(groupIndex);

    dispatch({ type: SET_GROUP_INDEX, payload: { groupIndex } });
  };
}

export function editGroup(group: GroupType) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const { auth, appUser } = getState();
    const groups = appUser.groups.map((g: GroupType) => {
      if (g.groupId === group.groupId) return group;
      return g;
    });

    if (auth.authStatus !== "signedIn") {
      setAppUser({ ...appUser, groups });
    }

    dispatch({ type: SET_GROUPS, payload: { groups } });
  };
}

export function updateGroup(group: GroupType) {
  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const { auth, appUser } = getState();
    const groups = appUser.groups.map((g: GroupType) => {
      if (g.groupId === group.groupId) return group;
      return g;
    });

    if (auth.authStatus !== "signedIn") {
      setAppUser({ ...appUser, groups });
    } else {
      fbstore.collection("users").doc(auth.uid).update({ groups });
    }

    dispatch({ type: SET_GROUPS, payload: { groups } });
  };
}

export function initializeAppSignedOut() {
  console.log("Initializing App");

  return async (dispatch: Dispatch<{}>) => {
    let appUser = await getAppUser();
    appUser = await normalizeAppUser(appUser);
    setAppUser(appUser);

    dispatch({ type: INITIALIZE_APP_USER, payload: appUser });
  };
}

export function onboardNewSignIn(uid: string) {
  return async () => {
    let localAppUser = await getAppUser();
    localAppUser = await normalizeAppUser(localAppUser);
    fbstore.collection("users").doc(uid).set(localAppUser);
  };
}

export function initializeAppSignedIn(appUser: any) {
  return async (dispatch: Dispatch<{}>) => {
    const nAppUser: AppUserType = await normalizeAppUser(appUser);
    localStorage.removeItem("appUser");
    localStorage.removeItem("groupId");
    dispatch({ type: INITIALIZE_APP_USER, payload: nAppUser });
  };
}
