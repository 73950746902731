import type { Dispatch } from "react";
import { AuthStatusType, RootStateType } from "../../models";
import { CLEAR_APP_USER } from "../appUser";
import { fbauth } from "../../firebase";

export const SET_NAME = "SET_NAME";
export const SET_AUTH = "SET_AUTH";
export const INITIALIZE = "INITIALIZE";
export const AUTH_SET_STATUS = "AUTH_SET_STATUS";
export const SET_SHOW_SIGNIN_FORM = "SET_SHOW_SIGNIN_FORM";
export const SET_SHOW_SIGNUP_FORM = "SET_SHOW_SIGNUP_FORM";
export const SET_SHOW_RESET_PASSWORD_FORM = "SET_SHOW_RESET_PASSWORD_FORM";

export const SET_CLOSE_AUTH_FORMS = "SET_CLOSE_AUTH_FORMS";
export const SIGN_OUT = "SIGN_OUT";

export type SetNameType = { type: string; name: string };
export type LoginType = { type: string; email: string; password: string };

export function setAuthStatus(authStatus: AuthStatusType) {
  return { type: AUTH_SET_STATUS, authStatus };
}

export function setAuth(auth: { email: string; emailVerified: boolean; uid: string }) {
  return { type: SET_AUTH, ...auth };
}

export function signIn(email: string, password: string) {}

export const signOut = () => ({ type: INITIALIZE });

export function setName(name: string): SetNameType {
  return { type: SET_NAME, name };
}

export const setShowSignInForm = (showSignInForm: boolean) => ({
  type: SET_SHOW_SIGNIN_FORM,
  showSignInForm,
});

export const setShowResetPasswordForm = (showResetPasswordForm: boolean) => ({
  type: SET_SHOW_RESET_PASSWORD_FORM,
  showResetPasswordForm,
});

export const setShowSignUpForm = (showSignUpForm: boolean) => ({
  type: SET_SHOW_SIGNUP_FORM,
  showSignUpForm,
});

export const setCloseAuthForms = () => ({ type: SET_CLOSE_AUTH_FORMS });

// This is an example of calling one action from another
export function testOf(name: string) {
  console.log("testof running");

  return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    const x = getState();
    console.log(x);

    dispatch(setName(name));
  };
}

export function signout() {
  return async (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
    dispatch({ type: SIGN_OUT });
    dispatch({ type: CLEAR_APP_USER });
    await fbauth.signOut();
    localStorage.clear();
  };
}
