import { Popover, PopoverTrigger, PopoverContent, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "../../models";
import { setCloseAuthForms, setShowSignUpForm } from "../../store/auth";
import SignUpForm from "./SignUpForm";
import ResetPasswordForm from "./ResetPasswordForm";

export default function () {
  const dispatch = useDispatch();

  const open = () => dispatch(setShowSignUpForm(true));
  const close = () => dispatch(setCloseAuthForms());
  const toggle = () => (showSignUpForm ? close() : open());

  const { showSignUpForm, showResetPasswordForm } = useSelector(
    (state: RootStateType) => state.auth,
  );

  // async function onSubmit(values: { email: string; password: string }) {
  //   const { email, password } = values;
  //   await fbauth
  //     .createUserWithEmailAndPassword(email, password)
  //     .then((res) => res.user?.sendEmailVerification())
  //     .catch((err) => setError("server", { message: err.message }));
  // }
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={showSignUpForm}
        placement="bottom-start"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Text style={{ cursor: "pointer" }} onClick={toggle} fontSize={18} fontWeight={500}>
            Sign Up
          </Text>
        </PopoverTrigger>
        <PopoverContent color="black">
          {!showResetPasswordForm && showSignUpForm && <SignUpForm />}
          {showResetPasswordForm && <ResetPasswordForm />}
        </PopoverContent>
      </Popover>
    </>
  );
}
