import { appVersion, random } from "../helpers";
import { initialTimeSetting, TimeSettingType } from "./time";

export interface GroupType {
  appVersion: Number;
  groupId: string;
  groupTitle: string;
  audienceIncludes: string[]; // array of the audience part of the group
  audienceSelected: string[]; // array of audiences to create the output
  dateCreated: string;
  timeSetting: TimeSettingType;
}

export let initialGroup = {
  appVersion,
  groupId: random(),
  groupTitle: "New Group",
  audienceIncludes: [] as string[], // array of the audience part of the group
  audienceSelected: [] as string[], // array of audiences to create the output
  dateCreated: Date.now().toString(),
  timeSetting: initialTimeSetting,
};

export interface GroupIdType {
  groupId: string;
}
