import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "../../models";
import React from "react";
import {
  initializeAppSignedIn,
  initializeAppSignedOut,
  onboardNewSignIn,
} from "../../store/appUser";
import { fbstore } from "../../firebase";

export default function () {
  const state = useSelector((s: RootStateType) => ({
    authStatus: s.auth.authStatus,
    uid: s.auth.uid,
  }));

  const { authStatus, uid } = state;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!authStatus) {
      dispatch(initializeAppSignedOut());
    } else if (authStatus === "signedIn") {
      fbstore
        .collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            dispatch(onboardNewSignIn(uid));
          } else {
            dispatch(initializeAppSignedIn(doc.data()));
          }
        });
    }
  }, [authStatus]);
}
