import React, { useEffect, useState } from "react";
import { Box, Button, HStack, IconButton, Text, Tooltip, VStack } from "@chakra-ui/react";
import { AudienceType, GroupType, TimeSettingType } from "../../models";
import copy from "copy-to-clipboard";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { updateGroup } from "../../store/appUser";
import { outputParser } from "../../helpers/parsing";
import { CalendarIcon, CopyIcon } from "@chakra-ui/icons";
import { isJSDocCommentContainingNode } from "typescript";
import { formatLink } from "../../helpers";

interface Props {
  dt: DateTime;
  endDt: DateTime;
  timeSetting: TimeSettingType;
  audiences: AudienceType[];
  group: GroupType;
}

export default function (props: Props) {
  const { timeSetting, audiences, group } = props;
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const copyPaste = () => {
    const output = audiences
      .map((a: AudienceType) => outputParser(timeSetting, a).string)
      .join("\n");
    console.log(output);
    copy(output);
    dispatch(updateGroup({ ...group, timeSetting }));
  };

  const googleCalendarLink = () => {
    dispatch(updateGroup({ ...group, timeSetting }));
    window.open(formatLink(timeSetting, audiences));
  };

  return (
    <Box display="flex" flexDir="row" width="100%">
      <Box alignItems="flex-start" flex={1} py="3%">
        {audiences.map((aud: AudienceType) => {
          const { id } = aud;
          const output = outputParser(timeSetting, aud);

          return (
            <Box key={id} pt="4px" fontSize="xs">
              <Text whiteSpace="pre-line" textAlign="left" isTruncated>
                {output.holder.join(" ")}
              </Text>
            </Box>
          );
        })}
      </Box>
      <Box
        px="10px"
        py="6px"
        borderLeft="1px solid #e6e6e6"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Tooltip label="Copy to Clipboard" fontSize="sm">
          <IconButton
            aria-label="Copy to Clipboard"
            size="xs"
            icon={<CopyIcon />}
            onClick={copyPaste}
          >
            Clip
          </IconButton>
        </Tooltip>
        <Tooltip label="Create a Google Invite" fontSize="sm">
          <IconButton
            aria-label="Google Calendar"
            size="xs"
            icon={<CalendarIcon />}
            onClick={googleCalendarLink}
            mt="12px"
          >
            Clip
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
