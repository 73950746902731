import React from "react";
import { HStack } from "@chakra-ui/react";

export default function (props: { children?: React.ReactNode; column?: boolean }) {
  return (
    <HStack
      flexDirection={props.column ? "column" : "row"}
      // borderBottom="1px solid #f1f1f1"
      pt="2px"
      // spacing="12px"
      mt={3}
    >
      {props.children}
    </HStack>
  );
}
