import { combineReducers, createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { auth } from "./auth";
import { appUser } from "./appUser";
import { timeSetting } from "./timeSetting";

export const rootReducer = combineReducers({ auth, appUser, timeSetting });

const middlewareEnhancer = applyMiddleware(thunkMiddleware);
let composedEnhancer = middlewareEnhancer;
if (process.env.REACT_APP_NODE_ENV === "development") {
  composedEnhancer = composeWithDevTools(middlewareEnhancer);
}

export default createStore(rootReducer, composedEnhancer);
