import { initialAudience, initialGroup } from "../models";
import { AppUserType, initialAppUser } from "../models/appUser";
import deepMerge from "./deepMerge";
import { getGroupId, setGroupId } from "./localStorage";

export default async function (appUser: AppUserType) {
  if (!appUser) {
    // If there is no local strorage it is created
    appUser = { ...initialAppUser };
    setGroupId(0);
  }
  if (appUser.groups?.length === 0) {
    appUser = {
      ...appUser,
      groups: [{ ...initialGroup, groupTitle: "Ardvarks" }],
    };
  }
  appUser = deepMerge(initialAppUser, appUser);
  appUser.groups = appUser.groups.map((g: any) => deepMerge(initialGroup, g));
  appUser.audiences = appUser.audiences.map((a: any) => deepMerge(initialAudience, a));

  let groupIndex = (await getGroupId()) || 0;
  if (appUser.groups.length - 1 < groupIndex) groupIndex = 0;
  return { ...appUser, groupIndex };
}
