import produce from "immer";
import { initialAuth, initialAuthForms } from "../../models";
import type { AuthType } from "../../models";
import {
  INITIALIZE,
  SET_AUTH,
  AUTH_SET_STATUS,
  SET_SHOW_SIGNIN_FORM,
  SET_CLOSE_AUTH_FORMS,
  SET_SHOW_SIGNUP_FORM,
  SET_SHOW_RESET_PASSWORD_FORM,
  SIGN_OUT,
} from "./actions";

export default produce(
  (draft: AuthType, action: any) => {
    switch (action.type) {
      case AUTH_SET_STATUS:
        draft.authStatus = action.authStatus;
        draft.loading = action.authStatus === "loading";
        return;
      case SET_AUTH:
        draft.email = action.email;
        draft.emailVerified = action.emailVerified;
        draft.uid = action.uid;
        return;
      case INITIALIZE:
        return initialAuth;
      case SET_SHOW_SIGNIN_FORM:
        draft = Object.assign(draft, initialAuthForms, {
          showSignInForm: action.showSignInForm,
        });
        return;
      case SET_SHOW_SIGNUP_FORM:
        draft = Object.assign(draft, initialAuthForms, {
          showSignUpForm: action.showSignUpForm,
        });
        return;
      case SET_SHOW_RESET_PASSWORD_FORM:
        draft.showResetPasswordForm = action.showResetPasswordForm;
        return;
      case SET_CLOSE_AUTH_FORMS:
        draft = Object.assign(draft, initialAuthForms);
        return;
      case SIGN_OUT:
        draft = Object.assign(draft, initialAuth);
        return;
      default:
        return;
    }
  },
  { ...initialAuth, authStatus: "loading", loading: true },
);
