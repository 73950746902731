// import { Dispatch } from "react";
// import { RootStateType, TimeSettingType } from "../../models";

export const SET_TIME_SETTING = "SET_TIME_SETTING";
export const SET_TIME_SETTING_HOUR = "SET_TIME_SETTING_HOUR";

// export const setHour = (hour: number) => ({ type: SET_TIME_SETTING_HOUR, payload: hour });
// export const setTimeSettings = (timeSetting: TimeSettingType, groupId: string) => {
//   return (dispatch: Dispatch<{}>, getState: () => RootStateType) => {
//     const { auth, appUser } = getState();
// const audiences = [...appUser.audiences, audience];

// if (!auth.authStatus) {
//   console.log("audiences", audiences);
//   setAppUser({ ...appUser, audiences });
// }

// dispatch({ type: SET_AUDIENCES, payload: { audiences } });
//   };
// };
