import React from "react";
import { Box, Flex, Text, Spacer, useToast, Center } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootStateType } from "../../models";
import { SignIn, SignOut, SignUpHolder } from "../auth";
import { fbauth } from "../../firebase";
import { LogoIconWhite } from "../common/branding";

function Item(props: { children: React.ReactNode }) {
  return <Box p={2}>{props.children}</Box>;
}

export default function () {
  const { email, authStatus } = useSelector((state: RootStateType) => state.auth);
  const loading = authStatus === "loading";
  const toast = useToast();

  return (
    <Flex direction="column">
      <Box backgroundColor="blue.800" p="10px" color="white">
        <Flex height={8} align="center">
          <LogoIconWhite width={30} />
          <Text fontSize={28} fontWeight={700} pl="18px">
            Huddle In
          </Text>
          <Spacer />
          {!email && !loading && (
            <Center>
              <SignIn />
              <Box px="12px" />
              <SignUpHolder />
            </Center>
          )}
          {email && !loading && <SignOut />}
        </Flex>
      </Box>
      {authStatus === "unverified" && (
        <Box backgroundColor="blue.800" alignContent="center">
          <Text display="inline-flex">Please verify your email to sync</Text>
          <Text
            display="inline-flex"
            pl="6px"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              fbauth.currentUser.sendEmailVerification().then((res) =>
                toast({
                  title: "Email Sent",
                  description: "Follow the email link to verify you email",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                }),
              );
            }}
            fontWeight={500}
          >
            Resend
          </Text>
        </Box>
      )}
    </Flex>
  );
}
