export interface AuthType extends AuthUserType, AuthFormType, AuthLoadingType {}

export interface AuthUserType {
  displayName: string;
  uid: string;
  email: string;
  emailVerified: boolean;
  authStatus: AuthStatusType;
}

export interface AuthFormType {
  showSignInForm: boolean;
  showSignUpForm: boolean;
  showResetPasswordForm: boolean;
}
export interface AuthLoadingType {
  loading: boolean;
}

const initialAuthLoading: AuthLoadingType = { loading: false };

export let initialAuthForms: AuthFormType = {
  showSignInForm: false,
  showSignUpForm: false,
  showResetPasswordForm: false,
};

export let initialAuthUser: AuthUserType = {
  displayName: "",
  uid: "",
  email: "",
  emailVerified: false,
  authStatus: "",
};

export let initialAuth: AuthType = {
  ...initialAuthUser,
  ...initialAuthForms,
  ...initialAuthLoading,
};

export type AuthStatusType = "" | "loading" | "unverified" | "signedIn";
