import { Box, Checkbox } from "@chakra-ui/react";
import { DatePicker } from "../common";
import { LocationType, TimeSettingType } from "../../models";
import { dateLong, timeFormatCheckboxes, TimeFormatCheckboxType } from "../../helpers";

type Props = { handleChange: Function; timeSetting: TimeSettingType };

export default function (props: Props) {
  const { handleChange, timeSetting } = props;

  return (
    <Box float="left">
      {timeFormatCheckboxes
        .filter((c) => c.using)
        .map((c: TimeFormatCheckboxType) => {
          const { label, key } = c;
          return (
            <Checkbox
              key={key}
              // @ts-ignore
              isChecked={timeSetting[key]}
              colorScheme="facebook"
              px={3}
              pb={2}
              onChange={(e) => handleChange({ [key]: e.target.checked })}
              size="sm"
            >
              {label}
            </Checkbox>
          );
        })}
    </Box>
  );
}
